const zh = {
	home: "首页",
	product_center: "产品中心",
	solution: "解决方案",
	service: "服务支持",
	about_us: "关于我们",
	stock_code: "股票代码",

	new_product: "新产品",

	company_info: {
		company_name: "公司名称",
		company_address: "公司地址",
		sales_hotline: "销售热线",
		company_switchboard: "公司总机",
		business_contact: "业务联系",
		official_website: "官方网站",
		fax: "传真",
		email: "邮箱",
		by_car: "乘车方式",
		support: "技术支持: 红檬智能"
	},

	about_us_info: {
		company_profile: "公司简介",
		news_information: "新闻资讯",
		recruitment: "人才资讯",
		contact_us: "联系我们"
	},

	about_company_info: {
		talent_advantage: "人才优势",
		message_from_chairman: "董事长致辞",
		company_culture: "企业文化",
		the_enterprise: "企业之蕴",
		the_enterprise_info: "企业之成",
		the_sum_of_the_business: "企业之和",
		the_quality_of_the_enterprise: "企业之质",
		enterprise_reputation: "企业之誉"
	},

	news_information_info: {
		company_news: "公司新闻",
		industy_news: "行业新闻",
		details: "详细信息"
	},

	recruitment_info: {
		job_offers: "招聘信息",
		details: "详细信息",
		job_information: "职位信息",
		job_requirements: "岗位要求",
		hr_info: "我们诚邀您的加盟, 您可将简历发送至hr@chinesesensor.com.若合适, 我们将会联系您!"
	},

	contact_us_info: {
		contact_HLH: "联系华兰海",
		tip_text: "无论你是否有技术问题或需要解决方案, 都可以向我们咨询, 与我们联系.",
		leave_a_message_online: "在线留言",
		phone: "电话",
		name: "姓名",
		email: "邮箱",
		message: "留言",
		submit: "提交"
	},

	service_info: {
		product_manual: "产品手册",
		technical_support: "技术支持",
		repair_and_maintenance: "维修维护",
		tip_text: "华兰海快速且高效的维修服务:",
		tip_text_content: "当您的测量产品需要维修时，我们将很乐意为您提供支持。在我们的工厂，专家将检查有缺陷的部件，并迅速彻底地进行相应的维修。我们与产品开发人员的密切合作，将确保我们可为您解决相关问题。",
		company: "公司",
		name: "姓名",
		phone: "电话",
		email: "邮箱",
		product_model: "产品型号",
		problem_description: "问题描述",
		submit: "提交"
	},

	more_info: "更多",
	contact_info: "联系信息",
	item_num: "No.",
	copy_info: "复制信息",
	
	nodata: "暂时没有数据!",
	
	
	sure: "确定",
	cancel: "取消",
	
	search_center: {
		title: '搜索中心',
		hot_key: '热门关键字',
		name: '名称',
		model: '型号',
		use: '用途',
		select_tag: '请选择标签',
		input_name: '请输入产品名',
		input_model: '请输入产品型号',
	},
	
	search: '搜索',
	
	product_info: {
		name: "产品名",
		model: "型号",
		date: "日期",
		summary: "描述",
		characteristic: "特点",
		use: "用途",
		structure_diagram: "结构图",
		parameters: "参数",
		parameter_key: "参数名",
		parameter_value: "参数值"
	}
};
export default zh;
