<template>
	<div id="commonHeader">
		<div class="header-content">
			<div class="header-content-left">
				<img @click="backToHome" style="height: 41px; cursor: pointer;" alt="华兰海logo"
					src="../assets/logo_icon.png" />
			</div>
			<div class="header-content-right">

				<!-- <div class="hot-line" style="font-weight: bold;">
					{{ $t("company_info.sales_hotline") }}: 400-8399-008 / (86)135-3261-7588
				</div> -->

				<div class="stock-symbol" style="font-weight: bold; margin-left: 15px;">
					{{ $t("stock_code") }}: 837228
				</div>

				<div class="header-content-right-contact" @click="contactUs"
					style="margin-left: 15px; margin-right: 15px; cursor: pointer;">
					{{ $t("about_us_info.contact_us") }}
				</div>
				<div class="header-content-right-language" @click="switchLanguage" style="cursor: pointer;width: 50px;">
					{{ languages[unSelectedLanguageIndex].title }}
				</div>
			</div>
		</div>
		<!-- 功能栏 -->
		<div class="header-func">
			<!--  -->
			<div class="header-func-bar" @mouseleave="hideSubFunc">
				<!-- 鼠标移入首页按钮时隐藏子菜单栏 -->
				<div class="func-btn" @click="backToHome" @mouseover="hideSubFunc">
					{{ $t("home") }}
				</div>
				<!-- 产品中心 解决方案 服务支持 关于我们 -->
				<div class="func-btn" @click="jupmto(index, 0)" @mouseover="showSubFunc(index)"
					:class="selectedIndex == index ? 'selected' : ''" v-for="(item, index) in funcList" :key="item.id">
					{{ cur_lang == "zh" ? item.nameCh : item.nameEn }}
				</div>

				<!-- 子级功能显示栏 -->
				<div class="header-func-sub" v-if="isShowFucSub">
					<div class="header-func-sub-top">
						<div class="header-func-sub-content" v-for="(subItem, subIndex) in selectedFunc"
							:key="subIndex">
							<div class="type-content" @click="jupmToTopType(subIndex, 0)">
								<el-image v-if="subItem.url != null" :src="(subItem.url.indexOf('resources') != -1 ? imageBaseURL + subItem.url + '?reduce=true' : subItem.url)
	            " style="width: 130px; height: 80px;" alt="功能栏" />
								<div
									style="font-weight: bold; line-height: 50px; color: #2C3E50; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
									{{ cur_lang == "zh" ? subItem.nameCh : subItem.nameEn }}
								</div>
							</div>

							<!-- 产品栏，显示5个 -->
							<div v-if="selectedIndex == 0">
								<div class="sub-type-content" @click="gotoModulePage(subIndex, subsubIdx)"
									v-for="(subsubItem, subsubIdx) in subItem.child.slice(0, 5)" :key="subsubIdx">
									{{
	              cur_lang == "zh" ? subsubItem.nameCh : subsubItem.nameEn
	            }}
								</div>
							</div>

							<!-- 方案栏 -->
							<div v-else-if="selectedIndex == 1">
								<div class="sub-type-content" @click="gotoModulePage(subIndex, subsubIdx)"
									v-for="(subsubItem, subsubIdx) in subItem.schemeList.slice(0, 5)" :key="subsubIdx">
									{{
							  cur_lang == "zh" ? subsubItem.titleCh : subsubItem.titleEn
							}}
								</div>
							</div>
							
							<!-- 服务支持、关于我们 -->
							<div v-else>
								<div class="sub-type-content" @click="gotoModulePage(subIndex, subsubIdx)"
									v-for="(subsubItem, subsubIdx) in subItem.child.slice(0, 5)" :key="subsubIdx">
									{{
	              cur_lang == "zh" ? subsubItem.titleCh : subsubItem.titleEn
	            }}
								</div>
							</div>
						</div>
					</div>
					<div class="func-more-btn"
						style="width: 100%; line-height: 40px; bottom: 5px; background-color: #163E87; color: #FFF;"
						@click="lookMoreInfo" v-if="selectedIndex == 1 || selectedIndex == 0">
						{{ $t("more_info") }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// 关于我们图片
	let about_us_01 = require("@/assets/about_us/company_icon.png");
	let about_us_02 = require("@/assets/about_us/news_icon.png");
	let about_us_03 = require("@/assets/about_us/talent_icon.png");
	let about_us_04 = require("@/assets/about_us/contact_icon.png");

	export default {
		name: "CommonHeader",
		data() {
			return {
				show: false,
				cur_lang: "zh",
				unSelectedLanguageIndex: 1,
				languages: [{
						id: 1,
						title: "中文"
					},
					{
						id: 2,
						title: "English"
					}
				],

				selectedIndex: -1,
				isShowFucSub: false,
				productCenterList: [],

				// 解决方案区
				solutionFuncList: [],

				// 关于我们区
				aboutUsFuncList: [{
						id: 1,
						nameCh: "公司简述",
						nameEn: "Company Profile",
						url: about_us_01,
						child: [{
								id: 11,
								titleCh: "公司简介",
								titleEn: "Introduction"
							},
							{
								id: 12,
								titleCh: "企业文化",
								titleEn: "Culture"
							},
							{
								id: 13,
								titleCh: "人才优势",
								titleEn: "Talent Advantage"
							},
							{
								id: 14,
								titleCh: "董事长致辞",
								titleEn: "Message from Chairman"
							}
						]
					},
					{
						id: 2,
						nameCh: "新闻资讯",
						nameEn: "News",
						url: about_us_02,
						child: [{
								id: 21,
								titleCh: "公司新闻",
								titleEn: "Company News"
							},
							{
								id: 22,
								titleCh: "行业资讯",
								titleEn: "Industy News"
							}
						]
					},
					{
						id: 3,
						nameCh: "人才资讯",
						nameEn: "Recruitment",
						url: about_us_03,
						child: [{
							id: 31,
							titleCh: "招聘信息",
							titleEn: "Job Offers"
						}]
					},
					{
						id: 4,
						nameCh: "联系我们",
						nameEn: "Contact Us",
						url: about_us_04,
						child: [{
							id: 41,
							titleCh: "联系华兰海",
							titleEn: "Contact Info"
						}]
					}
				],

				// 服务支持区
				serviceFuncList: [{
						id: 1,
						nameCh: "服务",
						nameEn: "Service",
						url: about_us_04,
						child: [{
							id: 11,
							titleCh: "传感器定制",
							titleEn: "Sensor customization"
						}]
					},
					{
						id: 2,
						nameCh: "支持",
						nameEn: "Support",
						url: about_us_03,
						child: [{
								id: 21,
								titleCh: "技术支持",
								titleEn: "Technical Support"
							},
							{
								id: 22,
								titleCh: "维修维护",
								titleEn: "Repair and Maintenance"
							}
						]
					},
					{
						id: 3,
						nameCh: "下载",
						nameEn: "Download",
						url: about_us_02,
						child: [{
							id: 31,
							titleCh: "产品手册",
							titleEn: "Product Manual"
						}]
					}
				],

				selectedFunc: [],
				funcList: [{
						id: 1,
						nameCh: "产品中心",
						nameEn: "Products"
					},
					{
						id: 2,
						nameCh: "解决方案",
						nameEn: "Solutions"
					},
					{
						id: 3,
						nameCh: "服务支持",
						nameEn: "Services",
						func: []
					},
					{
						id: 4,
						nameCh: "关于我们",
						nameEn: "About Us"
					}
				]
			};
		},

		created() {
			let locale = localStorage.getItem("locale");
			if (locale == null) {
				// 默认中文
				this.cur_lang = "zh";
				localStorage.setItem("locale", "zh");
			} else {
				this.cur_lang = locale;
			}
			this.$i18n.locale = this.cur_lang;

			if (this.cur_lang == "zh") {
				// 按钮显示 'English'
				this.unSelectedLanguageIndex = 1;
			} else {
				// 按钮显示 '中文'
				this.unSelectedLanguageIndex = 0;
			}
			// 获取显示的类型列表
			this.productCenterList = JSON.parse(sessionStorage.getItem('show_product_type_list'))
			this.solutionFuncList = JSON.parse(sessionStorage.getItem('show_solution_list'))
			if (this.productCenterList == null) {
				this.getProductType();
			}
			if (this.solutionFuncList == null) {
				this.getSolutionTag();
			}
		},

		mounted() {
			this.funcList[0].func = this.productCenterList;
			this.funcList[1].func = this.solutionFuncList;
			this.funcList[2].func = this.serviceFuncList;
			this.funcList[3].func = this.aboutUsFuncList;
		},

		methods: {
			backToHome: function() {
				window.location.href = '/'
			},

			contactUs: function() {
				window.location.href = 'contact.html'
			},

			switchLanguage: function() {
				this.unSelectedLanguageIndex = this.unSelectedLanguageIndex == 1 ? 0 : 1;
				if (this.unSelectedLanguageIndex == 1) {
					// 显示 中文
					this.$addStorageEvent(1, "locale", "zh");
					this.$i18n.locale = "zh";
					this.cur_lang = "zh";
				} else {
					// 显示 英文
					this.$addStorageEvent(1, "locale", "en");
					this.$i18n.locale = "en";
					this.cur_lang = "en";
				}
			},

			showSubFunc: function(index) {
				// console.log("显示子级菜单:", index)

				this.selectedFunc = this.funcList[index].func;
				this.selectedIndex = index;
				this.isShowFucSub = true;
			},

			hideSubFunc: function() {
				// console.log("隐藏子级菜单")
				this.isShowFucSub = false;
				this.selectedIndex = -1;
			},

			jupmto(selectedIndex, index) {
				// 点击功能栏的产品中心 显示大类详情
				// console.log(selectedIndex, '---------')
				if (selectedIndex == 0) {

					// sessionStorage.setItem('top_type_index', selectedIndex);
					// 默认选中第一个大类
					window.location.href = 'product_type.html?type_index=' + selectedIndex;
				} else if (selectedIndex == 1) {
					// 解决方案
					this.gotoSolution(0, 0);

				} else if (selectedIndex == 2) {
					window.location.href = 'custom.html'

				} else if (selectedIndex == 3) {
					window.location.href = 'company.html'
				}
			},

			jupmToTopType: function(typeIndex, index) {
				// 下拉菜单
				if (this.selectedIndex == 0) {
					// 显示的是 产品中心内容，点击具体的大类
					// sessionStorage.setItem('top_type_index', typeIndex);
					window.location.href = 'product_type.html?type_index=' + typeIndex;

				} else if (this.selectedIndex == 1) {
					// 解决方案
					this.gotoSolution(typeIndex, index);
				}

			},

			lookMoreInfo: function() {
				// 查看更多
				if (this.selectedIndex == 0) {
					// 产品中心 显示大类详情
					// sessionStorage.setItem('top_type_index', 0);
					// 默认显示第一个大类
					window.location.href = 'product_type.html?type_index=0';

				} else if (this.selectedIndex == 1) {
					// 解决方案
					this.gotoSolution(0, 0);
				}
			},

			getProductType: function() {
				let url = "api/product/dataList";
				let that = this;
				that.productCenterList = [];
				this.$utils.httpRequestGet(
					url,
					null,
					function success(res) {
						// 存储到sessionstorage中，发送事件通知
						that.$addStorageEvent(2, "all_product_type_list", JSON.stringify(res.data));
						// 大类只显示7个
						if (res.data.length > 7) {
							for (var i = 0; i < 7; i++) {
								let productType = res.data[i];
								that.productCenterList.push(productType);
							}
						} else {
							that.productCenterList = res.data;
						}
						// 用于产品中心栏显示
						sessionStorage.setItem('show_product_type_list', JSON.stringify(that.productCenterList))
						that.funcList[0].func = that.productCenterList;
					},
					function fail(res) {
						console.log("产品类型请求失败", res);
					}
				);
			},

			getSolutionTag: function() {
				let url = "api/scheme/dataList";
				let that = this;
				that.solutionFuncList = [];

				this.$utils.httpRequestGet(
					url,
					null,
					function success(res) {
						// 大类只显示7个
						if (res.datas.length > 7) {
							for (var i = 0; i < 7; i++) {
								let solution = res.datas[i];
								that.solutionFuncList.push(solution);
							}
						} else {
							that.solutionFuncList = res.datas;
						}
						
						that.funcList[1].func = that.solutionFuncList;
						// 用于解决方案栏显示
						sessionStorage.setItem('show_solution_list', JSON.stringify(that.solutionFuncList))
					},
					function fail(res) {
						console.log("方案标签请求失败", res);
					}
				);
			},

			gotoModulePage: function(subIndex, subsubIndex) {
				// console.log(this.selectedIndex, subIndex, subsubIndex);

				if (this.selectedIndex == 0) {
					// 产品中心 点击二级分类
					window.location.href = 'product.html?type_index=' + subIndex + '&type_sub_index=' + subsubIndex

				} else if (this.selectedIndex == 1) {
					// 解决方案
					this.gotoSolution(subIndex, subsubIndex);
				} else if (this.selectedIndex == 2) {
					// 服务支持
					this.gotoService(subIndex, subsubIndex);
				} else if (this.selectedIndex == 3) {
					// 关于我们
					this.gotoAboutUs(subIndex, subsubIndex);
				}
			},

			gotoSolution: function(subIndex, subsubIndex) {

				window.location.href = 'solutiondetail.html?solution_index=' + subIndex + '&solution_sub_index=' +
					subsubIndex
			},

			gotoService: function(subIndex, subsubIndex) {

				let routePath = "/";
				switch (subIndex) {
					case 0: {
						// 服务
						// 传感器定制
						routePath = "custom.html";
					}
					break;
				case 1: {
					// 支持
					if (subsubIndex == 0) {
						// 技术支持
						routePath = "support.html";
					} else {
						// 维修维护
						routePath = "maintain.html";
					}
				}
				break;
				case 2: {
					// 下载
					// 产品手册
					routePath = "manual.html";
				}
				break;
				default:
					routePath = "/";
				}

				window.location.href = routePath
			},

			gotoAboutUs: function(subIndex, subsubIndex) {
				let routePath = "contact.html";
				switch (subIndex) {
					case 0: {
						// 公司简述
						if (subsubIndex == 0) {
							// 公司简介
							routePath = "company.html";
						} else if (subsubIndex == 1) {
							// 企业文化
							routePath = "culture.html";
						} else if (subsubIndex == 2) {
							// 人才优势
							routePath = "talent.html";
						} else {
							// 董事长致辞
							routePath = "chairman.html";
						}
					}
					break;
				case 1: {
					// 新闻资讯
					if (subsubIndex == 0) {
						// 公司新闻
						routePath = "news.html";
					} else {
						// 行业资讯
						routePath = "info.html";
					}
				}
				break;
				case 2: {
					// 人才资讯->招聘信息
					routePath = "recruit.html";
				}
				break;
				default:
					// 联系我们->联系华兰海
					routePath = "contact.html";
				}
				// console.log('path: ', routePath)
				window.location.href = routePath
			}
		}
	};
</script>

<style>
	#commonHeader {
		width: 100%;
		background-color: #163e87;
		color: white;
		text-align: center;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

	}

	.header-content {
		width: 1200px;
		height: 100px;
		/* background-color: #ff1133; */
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.header-content-left {
		width: 45%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}

	.header-content-right {
		width: 55%;
		height: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;

		color: white;
		font-size: 15px;
	}

	.header-func {
		width: 100%;
		height: 44px;
		border-bottom: 5px solid #163e87;

		background-color: #d2deee;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.header-func-bar {
		height: 100%;
		width: 1200px;
		/* background-color: #163E87; */

		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		/* 相对定位 */
		position: relative;
	}

	.header-func-bar .func-btn {
		width: 120px;
		height: 44px;
		line-height: 44px;
		/* background-color: #42B983; */
		margin-right: 15px;
		color: #2c3e50;
		font-size: 20px;
		font-weight: bold;
		text-align: center;

		cursor: pointer;
	}

	.header-func-bar .func-btn:hover {
		background-color: #fff;
	}

	.header-func-bar .selected {
		background-color: #fff;
	}

	.header-func-bar .header-func-sub {
		background-color: #fff;
		width: 1200px;
		height: 380px;
		box-shadow: 0px 10px 10px -5px #999;
		/* 绝对定位 */
		position: absolute;
		top: 44px;
		left: 0px;
		right: 0px;
		/* 向前显示 */
		z-index: 1;

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}

	.header-func-sub-top {
		width: 100%;
		height: 330px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;

		overflow: hidden;
	}

	.header-func-sub-content {
		margin-top: 15px;
		margin-left: 15px;
		width: 150px;
		cursor: pointer;
	}

	.sub-type-content {
		margin-top: 6px;
		line-height: 30px;
		color: #2C3E50;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.sub-type-content:hover {
		color: #fff;
		background-color: #163e87;
	}

	.func-btn>ul>li>ul {
		position: absolute;
		display: none;
	}

	.func-btn>ul>li:hover ul {
		display: block;
	}
</style>
