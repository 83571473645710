const en = {
	home: "Home",
	product_center: "Products",
	solution: "Solution",
	service: "Service",
	about_us: "About Us",
	stock_code: "Stock Code",

	new_product: "New Product",

	company_info: {
		company_name: "Company Name",
		company_address: "Company Address",
		sales_hotline: "Sales Hotline",
		company_switchboard: "Company Switchboard",
		business_contact: "Business Contact",
		official_website: "Official Website",
		fax: "Fax",
		email: "E-mail",
		by_car: "By car",
		support: "Technical Support: Redlemon intelligent"
	},

	about_us_info: {
		company_profile: "Company Profile",
		news_information: "News",
		recruitment: "Recruitment",
		contact_us: "Contact Us"
	},

	about_company_info: {
		talent_advantage: "Talent Advantage",
		message_from_chairman: "Message from Chairman",
		company_culture: "Company Culture",
		the_enterprise: "The Enterprise",
		the_enterprise_info: "The Enterprise Info",
		the_sum_of_the_business: "The Sum of The Business",
		the_quality_of_the_enterprise: "The Quality of The Enterprise",
		enterprise_reputation: "Enterprise Reputation"
	},

	news_information_info: {
		company_news: "Company News",
		industy_news: "Industy News",
		details: "Details"
	},

	recruitment_info: {
		job_offers: "Job Offers",
		details: "Details",
		job_information: "Job Information",
		job_requirements: "Job Requirements",
		hr_info: "We sincerely invite you to join, you can send your resume to hr@chinesesensor.com. If appropriate, we will contact you!"
	},

	contact_us_info: {
		contact_HLH: "Contact Us",
		tip_text: "Whether you have technical problems or need solutions, you can consult us and contact us.",
		leave_a_message_online: "Leave a Message",
		phone: "Telephone",
		name: "Name",
		email: "E-mail",
		message: "Message",
		submit: "Submit"
	},

	service_info: {
		product_manual: "Product Manual",
		technical_support: "Technical Support",
		repair_and_maintenance: "Repair and Maintenance",
		tip_text: "Our fast and efficient repair service:",
		tip_text_content: "When your measurement product needs repair, we will be happy to support you. In our factory, experts will check the defective parts and carry out corresponding repairs quickly and thoroughly. Our close cooperation with product developers will ensure that we can solve related problems for you.",
		company: "Company",
		name: "name",
		phone: "Telephone",
		email: "E-mail",
		product_model: "Product Model",
		problem_description: "Problem Description",
		submit: "Submit"
	},

	more_info: "More",

	contact_info: "Contact Info",
	item_num: "No.",
	copy_info: "Copy",
	nodata: "No Data",

	sure: "Sure",
	cancel: "Cancel",
	
	search_center: {
		title: 'Search Center',
		hot_key: 'Popular Keywords',
		name: 'Name',
		model: 'Model',
		use: 'Use',
		select_tag: 'Select tag',
		input_name: 'Input product name',
		input_model: 'Input product model',
	},
	
	search: 'Search',

	product_info: {
		name: "Name",
		model: "Model",
		date: "Date",
		summary: "Summary",
		characteristic: "Characteristic",
		use: "Use",
		structure_diagram: "Structure Diagram",
		parameters: "Parameters",
		parameter_key: "Parameter Name",
		parameter_value: "Parameter Value"
	}
};
export default en;
