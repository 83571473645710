<template>
	<!-- 底部 -->
	<div id="commonBottom" v-if="contactUsInfo != null">
		<div class="bottom-up">
			<div class="bottom-up-left">
				<span>{{ $t("company_info.company_address") }}:
					{{
	      cur_lang == "zh"
	        ? contactUsInfo.addressCh
	        : contactUsInfo.addressEn
	    }}</span>
				<span>{{ $t("company_info.company_switchboard") }} :
					{{ contactUsInfo.phone }}</span>
				<span>{{ $t("company_info.fax") }} : {{ contactUsInfo.fax }}</span>
				<span>{{ $t("company_info.email") }} : {{ cur_lang == "zh" ? contactUsInfo.emailCh :contactUsInfo.emailEn  }}
				</span>
			</div>
			<div class="bottom-up-right">
				<div class="business-contact bottom-common">
					<span>{{ $t("company_info.business_contact") }}:</span>
					<div class="bottom-common-content">
						<img style="width: 10px; margin-right: 5px;" src="../assets/phone_icon.png" alt="业务联系" />
						<span>{{ cur_lang == "zh" ? contactUsInfo.businessCh : contactUsInfo.businessEn }}</span>
					</div>
				</div>
				<div class="sales-hotline bottom-common">
					<span>{{ $t("company_info.sales_hotline") }}:</span>
					<div class="bottom-common-content">
						<img style="width: 10px; margin-right: 5px;" src="../assets/phone_icon.png" alt="联系信息" />
						<span>{{ contactUsInfo.service }}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="bottom-down">
			<span style="margin-right: 20px;">Copyright © 2003 - {{ curYear }}
				{{
	    cur_lang == "zh" ? contactUsInfo.nameCh : contactUsInfo.nameEn
	  }}</span>

			<a v-if="(cur_lang == 'zh')" style="cursor: pointer; text-decoration: none; color: #FFF; margin-right: 20px;"
				href="https://beian.miit.gov.cn/" target="view_window" rel="nofollow">粤ICP备05043292号</a>
<!-- 
			<a style="cursor: pointer; text-decoration: none; color: #FFF;" href="https://www.hong-meng.com"
				target="view_window" rel="nofollow">{{ $t("company_info.support") }}</a> -->


		</div>
	</div>
</template>

<script>
	export default {
		name: "CommonBottom",
		data() {
			return {
				cur_lang: 'zh',
				curYear: "2021",
				contactUsInfo: null,
			};
		},

		created() {
			let cur_lang = localStorage.getItem("locale");
			if (cur_lang) {
				this.cur_lang = cur_lang
			}

			// 判断当前会话是否存在联系信息
			let contactUsInfoJson = sessionStorage.getItem('contact_info')
			
			if (contactUsInfoJson == null) {
				// console.log('来了。。。')
				this.getContactUsInfo();
			} else {
				this.contactUsInfo = JSON.parse(contactUsInfoJson);
			}

			let myDate = new Date();
			this.curYear = myDate.getFullYear();
		},

		mounted() {
			const that = this;
			//监听缓存中指定key的值变化
			window.addEventListener("setItem", function(e) {
				// console.log('监听到了~', e.newValue, e.oldValue);
				if (e.key == 'locale') {
					that.cur_lang = e.newValue;
				}
			});
		},

		methods: {
			getContactUsInfo: function() {
				let url = "api/about/getMyInfo";
				let that = this;
				this.$utils.httpRequestGet(
					url,
					null,
					function success(res) {
						that.contactUsInfo = res.data;
						sessionStorage.setItem('contact_info', JSON.stringify(that.contactUsInfo));
					},
					function fail(res) {
						console.log("获取联系方式请求失败", res);
					}
				);
			},

			gotoBackStage: function() {
				// TODO: 进入后台管理
			},

		}
	};
</script>

<style>
	#commonBottom {
		width: 100%;
		height: 160px;
		background-color: #163e87;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: white;

		position: absolute;
		left: 0px;
		bottom: 0px;

	}

	.bottom-up {
		width: 1200px;
		height: 120px;
		/* background-color: #42B983; */

		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		border-bottom: 1px solid rgba(200, 200, 200, 0.2);
	}

	.bottom-up-left {
		width: 47%;
		height: 120px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: flex-start;
		font-size: 13px;
	}

	.bottom-up-right {
		width: 47%;
		height: 100%;

		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: flex-start;

		font-size: 13px;
	}

	.bottom-up-right .bottom-common {
		width: 100%;
		height: 45%;

		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: flex-start;
	}

	.bottom-common .bottom-common-content {
		width: 100%;
		height: 45%;

		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	}

	.bottom-down {
		width: 1200px;
		height: 30px;

		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		/* background-color: #42B983; */

		font-size: 12px;
		font-weight: bold;
	}
</style>
